import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Table } from 'react-bootstrap'
import { MdDelete } from 'react-icons/md'
import Nav from './Nav'
import config from './config.json'

import { useForm } from "react-hook-form";

//import "./styles.css";

function TriggerAds() {
  const [clients, setClients] = useState([])

  useEffect(() => {
    fetchClients()
  }, [])


  const [clientIP, setClientIP] = useState('')
  const [clientSocat, setClientSocat] = useState('')

  const fetchClients = async () => {
    let url = config.SERVER_URL + 'list_clients_with_IP.php'
    const data = await fetch(url)
    const data_clients = await data.json()

    setClients(data_clients.clients_list)
  }

  const [checkedState, setCheckedState] = useState(
    new Array(clients.length).fill(false)
  );
  
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  }
  
//	const str = {site}
//	let screen = str.split(":")[0]
//	let address = str.split(":")[1]

 
    const handleAdStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartAdBreak_0.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
      const handle90AdStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartAdBreak_90.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
    const handle120AdStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartAdBreak_120.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
	

  return (
    
    <div>
      <Nav />
      <h5>Trigger Ads On Selected Screens</h5>
	  
      <ul className="toppings-list">
        {clients.map(( site , index) => {
          return (
            <li key={index}>
              <div className="toppings-list-item">
                <div className="left-section">
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={site}
                    value={site}
                    checked={checkedState[index]}
                    onChange={() => handleOnChange(index)}
					/>
                  <label htmlFor={`custom-checkbox-${index}`}> &emsp; {site}</label>
                </div>


				</div>
		<br></br>				
            </li>

		
          );
        })}

      </ul>
	  <Button id='save-schedule' className='save-button' onClick={handleAdStartClick}>Start All Ads </Button>
	  <Button id='save-schedule' className='save-button' onClick={handle90AdStartClick}>Start 90s Ads </Button>
	  <Button id='save-schedule' className='save-button' onClick={handle120AdStartClick}>Start 120s Ads </Button>
    </div>
  );
}

export default TriggerAds
