import React, { useState, useEffect } from 'react'
import {
  Table,
  Button,
  Modal,
  Form,
  Dropdown,
  ButtonGroup,
  DropdownButton,
} from 'react-bootstrap'
import TextField from '@material-ui/core/TextField'
import { RiAddLine } from 'react-icons/ri'
import { MdDelete, MdSave } from 'react-icons/md'
import Nav from './Nav'
import config from './config.json'
import AddSchedule from './AddSchedule'
import { TimePicker } from 'antd'
import moment from 'moment'

function Reporting () {
  
  return (
        <div>
        <Nav />
        <div>  
		<h4 className='schedule-head'>GPMAds Reporting</h4>
		</div>
		<h5>Click on the button below to open up the reporting console.  The user name is XXXX and the password is XXXXX</h5>
		<br></br>
		<div>
		
		<Button onClick={()=> window.open("http://gpmads.com/phpMyAdmin/index.php", "_blank")}>Open Reporting Console </Button>
		<br></br>
		<br></br>
		<h6>Be careful how you use the console - this is live data.  Don't delete anything unless you are SURE!!</h6>
		
		</div></div>
  )
}

export default Reporting
