import React from 'react'
import { Link } from 'react-router-dom'
import auth from './auth'

function Nav() {
  const navStyle = {
    color: 'white',
  }

  const handlelogout = () => {
    auth.logout(() => {})
  }

  return (
    <nav>
      <ul className='nav-links'>
        <Link style={navStyle} to='/manage_media'>
          <li>Manage Media</li>
        </Link>
        <Link style={navStyle} to='/manage_clients'>
          <li>Manage Clients</li>
        </Link>
		<Link style={navStyle} to='/manage_screens'>
          <li>Manage Screens</li>
        </Link>
		<Link style={navStyle} to='/reports'>
          <li>Reports</li>
        </Link>

<a href="http://gpmads.com/AdTrigger.html" target="_blank">Trigger Ads</a>

	

        <Link style={navStyle} to='/login' onClick={handlelogout}>
          <li>Logout</li>
        </Link>
      </ul>
    </nav>
  )
}

export default Nav
/*  	<Link style={navStyle} to='/trigger_ads'>
          <li>Trigger Ads</li>
        </Link>		
*/