import React, { useState, useEffect, Component } from 'react'
import {
  Table,
  Button,
  Modal,
  Form,
  Dropdown,
  ButtonGroup,
  DropdownButton,
} from 'react-bootstrap'
import TextField from '@material-ui/core/TextField'
import { RiAddLine } from 'react-icons/ri'
import { MdDelete, MdSave } from 'react-icons/md'
import Nav from './Nav'
import config from './config.json'
import AddSchedule from './AddSchedule'
import { TimePicker } from 'antd'
import moment from 'moment'


function ClientScreens(match) {
  useEffect(() => {
    fetchClientMedia()
  }, [])

  const [clientName, setClientName] = useState('')
  const [clientIP, setClientIP] = useState('')

  const [clientMatrix, setClientMatrix] = useState('')
  const [clientFreeview, setClientFreeview] = useState('')
  const [clientSocat, setClientSocat] = useState('')
  const [clientVLC, setClientVLC] = useState('')
 
/// THIS IS FOR SCREEN ON/OFF 
  const media = new Object('')
  const [clientnotBefore, setClientNotBefore] = useState('')
  const [clientnotAfter, setClientNotAfter] = useState('')
  const format = 'HH-mm'
  
  
  const [status, setStatus] = useState('')
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  
    const fetchClientMedia = async () => {
    const url = config.SERVER_URL + 'list_client_IP.php?client_id='  
    const data = await fetch(
      `${url}${match.match.params.id}`
    )
    const data_client_media = await data.json()
    setClientName(data_client_media.client_name)
	setClientIP(data_client_media.client_ip)
	
	setClientMatrix(data_client_media.client_matrix)
	setClientFreeview(data_client_media.client_freeview)
	setClientSocat(data_client_media.client_socat)
	setClientVLC(data_client_media.client_vlc)
	setClientNotBefore(data_client_media.client_NotBefore)
	setClientNotAfter(data_client_media.client_NotAfter)
  }

/// THIS IS FOR SCREEN ON/OFF   
   const handleChanged = () => {
    let req = {}
    let invalid = false
	
    if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()
	
	let url = config.SERVER_URL + 'SetScreenTimes.php/?client_ip=' + clientIP + ' ' + clientMatrix

    xhr.open('POST', url, true)

    xhr.send(fd)
  }


  const handlePowerONClick = () => {
    let req = {}
    let invalid = false

    if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()
	
	let url = config.SERVER_URL + 'ScreenPowerON.php/?client_ip=' + clientIP + ' ' + clientMatrix

    xhr.open('POST', url, true)

    xhr.send(fd)
  }

  const handlePowerOFFClick = () => {
    let req = {}
    let invalid = false
	
    if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()
	
	let url = config.SERVER_URL + 'ScreenPowerOFF.php/?client_ip=' + clientIP + ' ' + clientMatrix

    xhr.open('POST', url, true)

    xhr.send(fd)
  }

  const handleUnmuteClick = () => {
    let req = {}
    let invalid = false
 //   req.client_name = clientName
 //  req.client_ip = clientIP
    if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()
	
	let url = config.SERVER_URL + 'ScreenUnmute.php/?client_ip=' + clientIP + ' ' + clientMatrix
    xhr.open('POST', url, true)
    xhr.send(fd)
  }

  const handleMuteClick = () => {
    let req = {}
    let invalid = false
//   req.client_name = clientName
//   req.client_ip = clientIP
    if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()
	
	let url = config.SERVER_URL + 'ScreenMute.php/?client_ip=' + clientIP + ' ' + clientMatrix
    xhr.open('POST', url, true)
    xhr.send(fd)
  }

  const handleTVClick = () => {
    let req = {}
    let invalid = false
 //   req.client_name = clientName
 //   req.client_ip = clientIP
    if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()
	
	let url = config.SERVER_URL + 'ScreenTV.php/?client_ip=' + clientIP + ' ' + clientMatrix
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
 
   const handlePCClick = () => {
    let req = {}
    let invalid = false
//    req.client_name = clientName
//    req.client_ip = clientIP
    if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()
	
	let url = config.SERVER_URL + 'ScreenPC.php/?client_ip=' + clientIP + ' ' + clientMatrix
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
  /////////////////////////////////////////////////////////////////////////////////////////////
  
   const handleAutoAdsONClick = () => {
    let req = {}
    let invalid = false
    if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()
	
	let url = config.SERVER_URL + 'TasksOn.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
 
   const handleAutoAdsOFFClick = () => {
    let req = {}
    let invalid = false
    if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()
	
	let url = config.SERVER_URL + 'TasksOFF.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  
    const handleAdKillClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'KillAdBreak.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
 
      const handleUpdateAdsClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'UpdateAds.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
 
     const handleRebootClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'Reboot.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
     const handle2hrLoopStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartLoop_2hr.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
    const handle4hrLoopStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartLoop_4hr.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
    const handle8hrLoopStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartLoop_8hr.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
    const handle12hrLoopStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartLoop_12hr.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
  
    const handleAdStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartAdBreak_0.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
      const handle90AdStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartAdBreak_90.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }
  
    const handle120AdStartClick = () => {
    let req = {}
    let invalid = false
	if (invalid) {
      return
    }
    let fd = new FormData()
    fd.append('data', JSON.stringify(req))
    let xhr = new XMLHttpRequest()

	let url = config.SERVER_URL + 'StartAdBreak_120.php/?client_ip=' + clientIP + ' ' + clientSocat
    xhr.open('POST', url, true)
    xhr.send(fd)
  }

  if (clientName !== undefined)
    return (
      <div>
        <Nav />
        <h4 className='schedule-head'>Manage Screen for {clientName} on IP {clientIP}</h4>

        <Table className='schedule-table' striped bordered hover>
 
          <tbody>
		  <tr>
		  <td>Screen Power</td>
		  <td><input type="radio" value="On" name="power" onClick={handlePowerONClick}/> On</td>
		  <td><input type="radio" value="Off" name="power" onClick={handlePowerOFFClick}/> Off</td>
		  </tr>
		  <tr>
		  <td>Screen Audio</td>
		  <td><input type="radio" value="Unmute" name="audio" onClick={handleUnmuteClick}/> Unmute</td>
		  <td><input type="radio" value="Mute" name="audio" onClick={handleMuteClick}/> Mute</td>
		  </tr>		  
		  <tr>
		  <td>Screen Source</td>
		  <td><input type="radio" value="TV" name="source" onClick={handleTVClick}/> TV</td>
		  <td><input type="radio" value="PC" name="source" onClick={handlePCClick}/> PC</td>
		  </tr>	
	
	<tr>
		  <td>Screen Active Times (Not in use yet)</td>
      <td>
        <TimePicker
          id={media.id + '_not_before'}
          className='not-before-time'
          key={clientnotBefore}
     //     defaultValue={moment(clientnotBefore, format)}
		  defaultValue={moment((clientnotBefore), format)}
	//	  placeholder="HH:MM"
          format={format}
          onChange={handleChanged}
        /> Screen Auto ON
      </td>
      <td>
        <TimePicker
          id={media.id + '_not_after'}
          className='not-after-time'
          key={clientnotAfter}
          defaultValue={moment((clientnotAfter), format)}
		  placeholder="HH:MM"
          format={format}
          onChange={handleChanged}
        /> Screen Auto OFF
      </td>
	  </tr>

		  <tr>
		  <td>Auto Ads</td>
		  <td><input type="radio" value="Auto" name="AutoAds" onClick={handleAutoAdsONClick}/> Auto</td>
		  <td><input type="radio" value="Manual" name="AutoAds" onClick={handleAutoAdsOFFClick}/> Manual</td>
		  </tr>		  
		  <tr>
		  <td>Start Ad Break</td>
		  <td>
		  <Button id='save-schedule' className='save-button' onClick={handleAdStartClick}>Start Ads </Button>
		  </td>
		  <td>
		  <Button id='save-schedule' className='save-button' onClick={handle90AdStartClick}>Start 90s Ads </Button>
		  <Button id='save-schedule' className='save-button' onClick={handle120AdStartClick}>Start 120s Ads </Button>
		  </td>
		  </tr>	
		  
		  <tr>
		  <td>Start Ad Loop</td>
		  <td>
		  <Button id='save-schedule' className='save-button' onClick={handle2hrLoopStartClick}>2hr Loop </Button>
		  <Button id='save-schedule' className='save-button' onClick={handle4hrLoopStartClick}>4hr Loop </Button>
		  </td>
		  <td>
		  <Button id='save-schedule' className='save-button' onClick={handle8hrLoopStartClick}>8hr Loop </Button>
		  <Button id='save-schedule' className='save-button' onClick={handle12hrLoopStartClick}>12hr Loop </Button>
		  </td>
		  </tr>	
		  
		  
		  <tr>
		  <td>Kill Adverts / Loop</td>
		  <td><Button id='save-schedule' className='save-button' onClick={handleAdKillClick}>Kill Ads or Loop</Button>
		  </td>
		  </tr>			  

		  <tr>
		  <td>Force Schedule Update</td>
		  <td><Button id='save-schedule' className='save-button' onClick={handleUpdateAdsClick}>Update Ads</Button>
		  </td>
		  </tr>			  

		  <tr>
		  <td>Reboot PC</td>
		  <td><Button id='save-schedule' className='save-button' onClick={handleRebootClick}>Reboot</Button>
		  </td>
		  </tr>			  
		  
		  
		  <tr>
		  <td>Manage TV Schedule</td>
		  <td> <Button onClick={()=> window.open("http://" + clientIP + ":" + clientFreeview, "_blank")}>Open Interface </Button></td>
		  <td> root / gpm360 </td>
		  </tr>		 		  
          </tbody>
        </Table>
		<br>
		</br>
		  <Table className='schedule-table' striped bordered hover>
 
          <tbody>
		  <tr>
		  <td>IP is {clientIP}</td>
		  </tr>
  		  <tr>
		  <td>FreeView Port is {clientFreeview}</td>
		  </tr>
  		  <tr>
		  <td>Socat Port is {clientSocat}</td>
		  </tr>
  		  <tr>
		  <td>Matrix Port is {clientMatrix}</td>
		  </tr>
  		  <tr>
		  <td>VLC Port is {clientVLC}</td>
		  </tr>
		  </tbody>
        </Table>

      </div>
    )
  else return <div></div>
}



export default ClientScreens
